/* RED HAT TEXT */
@font-face {
  font-family: "Red Hat Text";
  font-weight: 400;
  font-style: normal;
  src: url("../assets/fonts/Red_Hat_Text/RedHatText-Regular.ttf")
    format("truetype");
}

@font-face {
  font-family: "Red Hat Text";
  font-weight: 400;
  font-style: italic;
  src: url("../assets/fonts/Red_Hat_Text/RedHatText-Italic.ttf")
    format("truetype");
}

@font-face {
  font-family: "Red Hat Text";
  font-weight: 700;
  font-style: normal;
  src: url("../assets/fonts/Red_Hat_Text/RedHatText-Bold.ttf")
    format("truetype");
}

/* ROBOTO MONO */
@font-face {
  font-family: "Roboto Mono";
  font-weight: 400;
  font-style: normal;
  src: url("../assets/fonts/Roboto_Mono/RobotoMono-VariableFont_wght.ttf")
    format("truetype-variations");
}
