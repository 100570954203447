$gray: #707070;
$light-gray: #a7a7a7;
$lighter-gray: #d1d1d1;
$hyperlink: #5ea3ff;
$hyperlink-alt: #ff9c5a;
$text: 0.75rem;
$small-text: 0.625rem;
$title-family: "Red Hat Text", sans-serif;
$body-family: "Roboto Mono", monospace, sans-serif;

body {
  color: $gray;
  font-family: $body-family;
}

h1,
h2,
h3 {
  font-family: $title-family;
  font-weight: 700;
}

h1 {
  font-size: 1.5rem;
  letter-spacing: 0.1em;
}

h2 {
  font-size: 1rem;
}

p {
  font-size: $text;
  line-height: 1.25;
  margin-bottom: 0;

  &.smaller {
    font-size: $small-text;
  }
}
a {
  color: $hyperlink;

  &:hover {
    color: $hyperlink-alt;
  }
}

hr {
  margin: 2rem 0;
  border-color: $gray;
  opacity: 0.5;
}

main {
  overflow-x: hidden;

  > section {
    position: relative;
    /* padding-top: 56px; */

    &#home {
      min-height: 100vh;
      /* Fallback for browsers that do not support Custom Properties */
      height: 100vh;
      height: calc(var(--vh, 1vh) * 100);

      @media (min-width: 576px) {
        height: auto;
      }

      @media (min-width: 768px) {
        /* Fallback for browsers that do not support Custom Properties */
        height: 100vh;
        height: calc(var(--vh, 1vh) * 100);
      }
    }

    &#work,
    &#school {
      .container {
        position: relative;
      }
    }
  }
}

footer {
  margin-top: 5rem;
  padding: 1rem 0;
  color: #fff;
  background: $gray;

  p {
    font-size: $small-text;
    opacity: 0.67;
  }
}

.op-50 {
  opacity: 0.5;
}
.op-67 {
  opacity: 0.67;
}

.mt-32 {
  margin-top: 2rem;
}

.mb-32 {
  margin-bottom: 2rem;
}

.container,
.container-fluid {
  padding: 0;
}

.row:not(.no-gutters) {
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}

.row:not(.no-gutters) [class*="col-"],
.row:not(.no-gutters) [class*="col-"] {
  padding-right: 0.5rem;
  padding-left: 0.5rem;
}

.row.no-gutters [class*="col-"],
.row.no-gutters [class*="col-"] {
  padding-right: 0;
  padding-left: 0;
}

.LazyPlaceholder {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: #fff;
  z-index: 9999;

  > div {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;

    > p {
      margin-bottom: 1.5rem;
    }

    > img {
      width: 2.5rem;
    }
  }
}

.BackgroundHeading {
  font-size: 4rem;
  opacity: 0.25;
  z-index: -1;

  &.WorkHeading {
    position: absolute;
    top: 2.5rem;
    right: -17.5%;
    font-size: 12rem;
    line-height: 0.9;
    letter-spacing: normal;
    text-align: right;
    opacity: 0.1;

    @media (min-width: 576px) {
      right: -12.5%;
    }

    @media (min-width: 768px) {
      right: -7.5%;
    }

    @media (min-width: 992px) {
      right: -5%;
    }

    @media (min-width: 1440px) {
      right: 0;
    }
  }

  &.SchoolHeading {
    position: absolute;
    top: 6rem;
    left: -17.5%;
    font-size: 12rem;
    line-height: 0.9;
    letter-spacing: normal;
    text-align: left;
    opacity: 0.1;

    @media (min-width: 576px) {
      left: -10%;
    }

    @media (min-width: 768px) {
      left: -7.5%;
    }

    @media (min-width: 992px) {
      left: -5%;
    }
  }
}

.AppNav {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 56px;
  background-color: rgba(255, 255, 255, 0.75);
  z-index: 1030;
}

.NavLogo {
  font: 700 1rem "Red Hat Text", sans-serif;
  letter-spacing: 0.1em;
  text-decoration: none;
  color: inherit;
  transition: all 0.2s ease-in-out;

  &:hover {
    color: $hyperlink;
    text-decoration: none;
  }
}

.AppMenu {
  position: relative;
  padding: 0;
  background: transparent;
  border: 0;
  /* border: 1px solid $gray; */
  width: 1.5rem;
  height: 1.5rem;

  > img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 1rem;
    height: auto;
  }
}

/* @keyframes menuHideAnimation {
    0% {
        display: block;
        opacity: 1;
    }

    99% {
        display: block;
        opacity: 0;
    }

    100% {
        display: none;
    }
} */

.Menu {
  position: fixed;
  display: none;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #fff;
  opacity: 0;
  z-index: 1060;

  &.MenuShow {
    display: block;
  }

  &.key_MenuHide {
    animation: menuHideAnimation 1s ease-out 0s 1 normal forwards;
  }

  > h1 {
    text-align: center;
    /* margin-top: 3.5rem; */
    margin-bottom: 2rem;
  }

  ul {
    list-style-type: none;
    margin: 0;
    padding-left: 48px;

    li {
      position: relative;

      &:not(.separator) {
        padding: 0.5rem;
      }

      &::before {
        content: "";
        position: absolute;
        top: 1.25rem;
        left: -16px;
        width: 16px;
        height: 2px;
        background: $gray;
        opacity: 0.5;
      }

      &:hover {
        &::before {
          opacity: 1;
        }
      }

      &.separator {
        width: 100%;
        margin-left: -1.5rem;
        margin-top: 1rem;
        margin-bottom: 1rem;
        border-bottom: 2px solid $lighter-gray;
        opacity: 0.5;

        &::before {
          content: unset;
        }
      }
    }
  }
}

.HomeName {
  position: relative;
  font-size: 2rem;
  margin-bottom: 0;

  &::after {
    content: "[ʒuˈɐ̃w̃]";
    position: absolute;
    bottom: -0.75rem;
    left: 0;
    font: 400 $text/1 $body-family;
    letter-spacing: normal;
    opacity: 0.5;
  }
}

.HomePicture {
  position: relative;
  width: 100%;
  height: 0;
  padding-top: 100%;

  background-color: #eee;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  border: 1px solid $gray;
  border-radius: 50%;
}

.HomePicturePattern {
  position: absolute;
  top: 1.5rem;
  left: 1.5rem;
  width: 125%;
  height: 150%;

  background-position: left;
  background-repeat: no-repeat;
  background-size: cover;

  z-index: -1;

  @media (min-width: 576px) {
    left: 2rem;
  }

  @media (min-width: 768px) {
    top: 0;
    width: 150%;
    height: 175%;
  }

  @media (min-width: 992px) {
    background-size: contain;
  }
}

.HomeStatement {
  text-align: center;

  &:first-child {
    margin-bottom: 1.5rem;
  }

  &.code > strong {
    color: $hyperlink;
  }

  &.design > strong {
    position: relative;

    &::before {
      content: "";
      position: absolute;
      top: 0;
      right: -0.25rem;
      width: 1.25rem;
      height: 1.75rem;
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      background-image: url("../assets/home/design_grid.svg");
      z-index: -1;
    }
  }
}

.HomeIcon {
  position: relative;
  display: flex;
  align-items: center;
  height: 100%;

  @media (min-width: 576px) {
    padding: 0.5rem;
  }

  @media (min-width: 768px) {
    padding: 0;
  }

  @media (min-width: 992px) {
    padding: 0.5rem;
  }

  > img {
    position: relative;
    width: 100%;
    padding: 0.5rem;
    z-index: 2;
  }

  &::before {
    content: "";
    position: absolute;
    top: 0.25rem;
    left: 0;
    width: 100%;
    height: 1rem;
    background-position-x: 0;
    background-size: 1rem;
    background-image: url("../assets/contacts/share_wiggle.svg");
    opacity: 0;
    transition: all 0.2s ease-in-out;
    z-index: -1;
  }

  &.pencil::after {
    content: "Conceptualize";
  }

  &.layout::after {
    content: "Design";
  }

  &.code::after {
    content: "Implement";
  }

  &.happy::after {
    content: "Be happy";
  }

  &::after {
    position: absolute;
    top: calc(100%);
    left: 50%;
    transform: translateX(-50%);
    font-size: $small-text;
    font-style: italic;
    white-space: nowrap;
    opacity: 0;
    transition: all 0.2s ease-in-out;
  }

  &:hover {
    &::before {
      background-position-x: 2rem;
      opacity: 1;
    }

    &::after {
      top: calc(100% + 0.5rem);
      opacity: 1;
    }
  }
}

.HomeScrolling {
  position: absolute;
  bottom: 1rem;
  left: 0;
  width: 100%;
  text-align: center;

  @media (min-width: 576px) {
    display: none;
  }

  @media (min-width: 768px) {
    display: block;
  }
}

#HomeKey_1,
#HomeKey_2,
#HomeKey_3,
#HomeKey_4 {
  opacity: 0;
}

.AboutBio {
  position: relative;
  font-size: $small-text;

  @media (min-width: 576px) {
    padding: 1rem 0;
  }

  ul {
    padding-left: 4rem;
    list-style: none;

    > li {
      position: relative;

      &::before {
        content: "";
        position: absolute;
        top: 0.375rem;
        right: calc(100% + 0.25rem);
        width: 16px;
        height: 1px;
        background: $gray;
        opacity: 0.67;
      }
    }
  }
}

.AboutBioPattern {
  position: absolute;
  top: -3rem;
  right: calc(100% + 1rem);
  width: 50%;
  height: 200%;

  background-position: right;
  background-repeat: no-repeat;
  background-size: cover;

  z-index: -1;

  @media (min-width: 576px) {
    height: 200%;
  }

  @media (min-width: 768px) {
    right: 150%;
    height: 150%;
    background-size: contain;
  }
}

.AboutWho {
  position: relative;

  &::after {
    content: "";
    position: absolute;
    top: -2rem;
    left: -2rem;
    width: calc(100% + 4rem);
    height: calc(100% + 4rem);
    border: 1px solid $gray;
    border-radius: 2px;
    opacity: 0.67;

    @media (min-width: 576px) {
      width: calc(100% + 12rem);
      left: -10rem;
    }

    @media (min-width: 992px) {
      width: calc(100% + 4rem);
      left: -2rem;
    }
  }
}

#AboutKey_1 {
  opacity: 0;
}

.SkillsTabs {
  border-bottom: 1px solid $gray;

  .nav-link {
    position: relative;
    font-size: $text;
    color: $gray;
    padding: 0.75rem 0.5rem;
    padding-right: 1rem;
    border-top-left-radius: 0;
    border-top-right-radius: 0;

    &.active::before {
      content: "";
      position: absolute;
      top: -1px;
      right: -1px;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 0 0.75rem 0.75rem 0;
      border-color: transparent #fff transparent transparent;
      z-index: 2;
    }

    &.active::after {
      content: "";
      position: absolute;
      top: 4px;
      right: -3px;
      width: 16px;
      height: 1px;
      border-bottom: 1px solid $gray;
      transform: rotate(45deg);
      z-index: 2;
    }

    &.active {
      border-color: $gray $gray #fff;
    }

    &:not(.active):hover {
      border-color: $lighter-gray $lighter-gray $gray;

      &::before {
        content: "";
        position: absolute;
        top: -1px;
        right: -1px;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 0 0.75rem 0.75rem 0;
        border-color: transparent #fff transparent transparent;
        z-index: 2;
      }

      &::after {
        content: "";
        position: absolute;
        top: 4px;
        right: -3px;
        width: 16px;
        height: 1px;
        border-bottom: 1px solid $lighter-gray;
        transform: rotate(45deg);
        z-index: 2;
      }
    }
  }
}

.tab-content {
  border: 1px solid $gray;
  border-top: 0;
  border-radius: 2px;
  border-top-left-radius: 0;

  > div {
    padding: 1rem 0.5rem 0 0.5rem;

    > ul {
      list-style: none;
      font-size: $text;
      padding-left: 1.5rem;

      > li {
        position: relative;
        margin-bottom: 0.75rem;

        &::before {
          content: "";
          position: absolute;
          top: 1px;
          left: -1.25rem;
          width: 1rem;
          height: 1rem;
          background-position: center;
          background-size: contain;
          background-repeat: no-repeat;
          background-image: url("../assets/skills/folder.svg");
          opacity: 0.67;
        }

        > ul {
          list-style: none;
          padding-left: 1rem;

          > li {
            position: relative;
            margin: 0.25rem 0 0.25rem 0;

            &::before {
              content: "";
              position: absolute;
              top: -2px;
              left: -1.75rem;
              width: 1.25rem;
              height: 0.75rem;
              border-left: 1px solid $gray;
              border-bottom: 1px solid $gray;
            }
          }
        }
      }
    }
  }
}

.WorkEntry {
  position: relative;
  padding-left: 1rem;
  margin-left: 0.5rem;
  margin-bottom: 2rem;

  &:last-child {
    margin-bottom: 0;
  }

  &::before {
    content: "";
    position: absolute;
    top: 11px;
    left: -0.375rem;
    width: 0.5rem;
    height: 0.5rem;
    border: 1px solid $gray;
    border-radius: 50%;
  }

  &::after {
    content: "";
    position: absolute;
    top: 1.5rem;
    left: -3px;
    height: calc(100% + 0.875rem);
    border-left: 1px solid $gray;
    opacity: 0.5;
  }

  &:last-child::after {
    height: calc(100% - 1.75rem);
  }

  .EntryDate {
    position: relative;
    display: inline-block;
    left: -0.25rem;
    font-size: $small-text;
    padding: 0.25rem 0.5rem;
    margin-bottom: 0.5rem;
    color: #fff;
    background-color: $light-gray;
    border-radius: 2px;

    &::before {
      content: "";
      position: absolute;
      top: 50%;
      left: -4px;
      transform: translateY(-50%);
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 0.25rem 0.25rem 0.25rem 0;
      border-color: transparent $light-gray transparent transparent;
      z-index: 6;
    }
  }

  h2 {
    width: 65%;
  }

  .EntryCompany {
    display: inline-flex;
    align-items: center;
    margin-bottom: 0.5rem;

    img {
      width: 1rem;
      height: auto;
      margin-right: 0.25rem;
      border-radius: 2px;
    }

    p {
      position: relative;
      top: 1px;
    }
  }

  ul {
    font-size: $small-text;
    list-style: none;
    padding-left: 1.25rem;
    margin-bottom: 0.5rem;
    opacity: 0.8;

    li {
      position: relative;
      margin-bottom: 0.25rem;

      &::before {
        content: "";
        position: absolute;
        top: 6px;
        left: -1rem;
        width: 0.75rem;
        height: 2px;
        background: $gray;
        opacity: 0.5;
      }
    }
  }

  .EntryTags {
    display: flex;
    flex-wrap: wrap;
    align-items: center;

    > span {
      margin-bottom: 0.25rem;
      margin-right: 0.25rem;
      padding: 0.25rem;
      font-size: $small-text;
      font-weight: 700;
      white-space: nowrap;
      text-transform: uppercase;
      color: #fff;
      background-color: $gray;
      border-radius: 2px;
    }
  }
}

.SchoolEntry {
  position: relative;
  padding-left: 1rem;
  margin-top: 1rem;

  .EntryDate {
    position: relative;
    display: inline-block;
    font-size: $small-text;
    margin-bottom: 0.25rem;
  }

  h2 {
    width: 65%;
  }

  .EntryCompany {
    display: inline-flex;
    align-items: center;
    margin-bottom: 0.5rem;

    img {
      width: 1rem;
      height: auto;
      margin-right: 0.25rem;
      border-radius: 2px;
    }

    p {
      position: relative;
      top: 1px;
    }
  }

  ul {
    font-size: $small-text;
    list-style: none;
    padding-left: 1.25rem;
    margin-bottom: 0.5rem;
    opacity: 0.67;

    li {
      position: relative;
      margin-bottom: 0.25rem;

      &::before {
        content: "";
        position: absolute;
        top: 6px;
        left: -1rem;
        width: 0.75rem;
        height: 2px;
        background: $gray;
        opacity: 0.5;
      }
    }
  }
}

.SchoolWrapper {
  position: relative;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 2rem;
    bottom: 0;
    right: 2rem;
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-image: url("../assets/school/line.svg");
    z-index: -1;
  }

  .SchoolPatternWrapper {
    position: relative;
    height: 100%;

    > img {
      position: absolute;
      top: 2rem;
      left: -2rem;
      width: calc(100% + 2rem);
    }
  }
}

.ContactsList {
  list-style: none;
  font-size: $text;
  padding-left: 0;
  margin-bottom: 2rem;

  > li {
    margin-bottom: 1.5rem;

    > a {
      > span {
        position: relative;

        > img {
          width: 16px;
          opacity: 0.67;
        }

        &::after {
          content: "";
          position: absolute;
          top: -2px;
          bottom: -2px;
          left: -2px;
          right: -2px;
          background-size: 2px;
          background-image: url("../assets/contacts/overlay.png");
          opacity: 0.25;
        }
      }
    }
  }
}

.ShareButton {
  position: relative;
  padding: 1rem;
  margin-bottom: 1rem;
  font-size: $text;
  font-weight: 700;
  color: $gray;
  background-color: #fff;
  border: 1px solid $gray;
  transition: color 0.1s ease-in-out;
  cursor: pointer;

  &::before {
    content: "";
    position: absolute;
    top: 0.75rem;
    left: 0.75rem;
    width: 100%;
    height: 100%;
    background-size: 1rem;
    background-image: url("../assets/contacts/share_wiggle.svg");
    transition: transform 0.2s ease-in-out;
    z-index: -1;
  }

  &:hover {
    color: $hyperlink;

    &::before {
      transform: translate(0.25rem, 0.25rem);
      background-image: url("../assets/contacts/share_wiggle_hover.svg");
    }

    > img {
      transform: translateX(1.75rem);
    }
  }

  &:focus {
    outline: 0;
  }

  > img {
    position: relative;
    top: -2px;
    width: 1.5rem;
    height: auto;
    margin-left: 0.5rem;
    transition: transform 0.3s ease-out;
  }
}
